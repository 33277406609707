/**
 * this library handles custom form inputs by mapping data-custom-input to a
 * behavior method
 *
 * Layout Hint:
 *
 * <input data-custom-input="dropdown"/>
 */

(() => {
    "use strict";

    const lib = {};

    // fuse functions
    lib.fuse = {
        settings: {
            display_menu: true, // automatically launch menu when you click in
        },
        hideAllFuseList: (event) => {
            document.querySelectorAll(`.autocomplete-items`).forEach(($fuse_list) => {
                $fuse_list.style.opacity = "0";
                $fuse_list.style.display = "none";
                $fuse_list.style.opacity = "1";
            });
        },
        handleInputEvents: (event) => {
            // behavior note: we are rendering the full list out by default. When we
            // search for items, the original fully rendered list will hide all entries
            // and then add only the search results, which will be visible.
            const $input = event.currentTarget;
            const $fuse_list = document.querySelector(`[data-for="${$input.id}"]`);

            if (!$fuse_list) {
                return;
            }

            let show_codes = $input.dataset.showCodes === "true";

            // hide the list when not in use
            if (event.type === "blur") {
                setTimeout(() => {
                    lib.fuse.hideAllFuseList();
                }, 350);
                return;
            }

            // change how we display base on display_menu
            if ($input.value.length <= 0 && !lib.fuse.settings.display_menu) {
                $fuse_list.style.display = "none";
            } else {
                $fuse_list.style.display = "block";
            }
            $input.currentFocus = -1;

            // cleanup the list display
            Array.from($fuse_list.children).forEach((child) => {
                if (child.hasAttribute("data-search-item")) {
                    child.remove();
                } else {
                    child.style.display = "none";
                    child.setAttribute("data-hidden", "true");
                }
            });
            $fuse_list.scrollTop = 0;

            if ($input.value.length > 0) {
                // search filtering
                let search_results = $input.fuse.search($input.value, {limit: 20});
                if (search_results.length <= 0) {
                    if (!lib.fuse.settings.display_menu) {
                        $fuse_list.style.display = "none";
                    }
                    return;
                }

                let $search_fragment = new DocumentFragment();
                search_results.forEach((result) => {
                    let item = result.item;

                    // append matched results to the end of the list
                    let $div = document.createElement("DIV");
                    $div.setAttribute("data-search-item", "true");
                    $div.setAttribute("data-fuse-entry", "true");
                    $div.setAttribute("data-hidden", "false");
                    $div.setAttribute("data-code", item.code);
                    $div.setAttribute("data-description", item.description);
                    if (show_codes) {
                        $div.innerHTML =
                            "<span>" + item.code + "</span> " + item.description;
                        $div.innerHTML +=
                            "<input type='hidden' value='" +
                            $div.code +
                            " - " +
                            $div.description +
                            "'>";
                    } else {
                        $div.innerHTML = item.description;
                        $div.innerHTML +=
                            "<input type='hidden' value='" + item.description + "'>";
                    }
                    $search_fragment.append($div);
                });
                $fuse_list.append($search_fragment);
                const $valid_nav_items = $fuse_list.querySelectorAll(
                    '[data-hidden="false"]'
                );
                $input.currentFocus++;
                lib.fuse.addActiveState($input, $valid_nav_items);
            } else {
                // no search filtering
                Array.from($fuse_list.children).forEach((child) => {
                    child.style.display = "block";
                    child.setAttribute("data-hidden", "false");
                });
            }
        },
        generateDOMResultList: ($input, collection) => {
            const $fuse_list_div = document.createElement("DIV");
            $fuse_list_div.setAttribute("id", "autocomplete-list");
            $fuse_list_div.setAttribute("class", "autocomplete-items");
            $fuse_list_div.setAttribute("data-for", $input.getAttribute("id"));
            $fuse_list_div.style.display = "none";

            if (
                typeof collection !== "object" ||
                Object.keys(collection).length < 1
            ) {
                return;
            }

            collection.forEach((entry) => {
                let $output = document.createElement("DIV");
                $output.setAttribute("data-fuse-entry", "true");
                $output.setAttribute("data-code", entry.code);
                $output.setAttribute("data-description", entry.description);

                let show_codes = $input.dataset.showCodes === "true";

                if (show_codes) {
                    $output.innerHTML =
                        "<span>" + entry.code + "</span> " + entry.description;
                    $output.innerHTML +=
                        "<input type='hidden' value='" +
                        entry.code +
                        " - " +
                        entry.description +
                        "'>";
                } else {
                    $output.innerHTML = entry.description;
                    $output.innerHTML +=
                        "<input type='hidden' value='" + entry.description + "'>";
                }

                $fuse_list_div.appendChild($output);
            });

            return $fuse_list_div;
        },
        attachFuseListToDOM: ($input, $list) => {
            const $target = $input.parentElement;
            if (typeof $list === "undefined") {
                return;
            }
            $target.appendChild($list);
            return $target;
        },
        registerTargetEvents: ($input, $target) => {
            if (typeof $target === "undefined") {
                return;
            }
            ["click"].forEach(event_type => {
                $target.addEventListener(event_type, (event) => {
                    // if we clicked a fuse entry, lets change our input to match itc
                    let $clicked_entry = event.target.hasAttribute("data-fuse-entry")
                        ? event.target
                        : event.target.closest("[data-fuse-entry]");
                    if ($clicked_entry && $clicked_entry.hasAttribute("data-fuse-entry")) {
                        // get the value from a text node after a div containing codes,
                        // or the text content if the code div doesn't exist
                        $input.value =
                            $clicked_entry.childNodes[1] &&
                            $clicked_entry.childNodes[1].nodeValue
                                ? $clicked_entry.childNodes[1].nodeValue
                                : $clicked_entry.textContent;
                        $input.setAttribute("data-code", $clicked_entry.dataset.code);
                        $input.setAttribute(
                            "data-description",
                            $clicked_entry.dataset.description
                        );

                        // if the input has data-code-input, set the value of that input to the code
                        if ($input.dataset.codeInput) {
                            const $code_input = document.querySelector($input.dataset.codeInput);
                            if ($code_input) {
                              $code_input.value = $clicked_entry.dataset.code;
                            }
                        }

                        // if the input has data-description-input, set the value of that input to the description
                        if ($input.dataset.descriptionInput) {
                            const $description_input = document.querySelector($input.dataset.descriptionInput);
                            if ($description_input) {
                                $description_input.value = $clicked_entry.dataset.description;
                            }
                        }

                        // let's also automatically click the + button
                        if ($input && $input.parentNode) {
                            const $add_button = $input.parentNode.querySelector(
                                '[data-repeatable-action^="add-"]'
                            );
                            $add_button && $add_button.click();
                        }

                        // hide event for fuse dropdown menus
                        document.dispatchEvent(
                            new CustomEvent("hide-fuse-menu")
                        );
                    }
                });
            });
        },
        registerInputEvents: ($input) => {
            document.addEventListener("hide-fuse-menu", () => {
                lib.fuse.hideAllFuseList();
            });
            ["focus", "blur", "input"].forEach((event_type) => {
                $input.addEventListener(event_type, lib.fuse.handleInputEvents);
            });
            ["keyup"].forEach((event_type) => {
                $input.addEventListener(event_type, () => {
                    $input.removeAttribute("data-code");
                    $input.removeAttribute("data-description");
                });
            });
        },
        registerKeyboardEvents: ($input) => {
            $input.addEventListener("keydown", (event) => {
                const $fuse_list = $input.parentElement.querySelector(
                    `[data-for="${$input.getAttribute("id")}"]`
                );

                if (!$fuse_list) {
                    // register auto-add even if there is no fuse list
                    if (event.keyCode === 13) {
                        const $add_button = $input.parentElement.querySelector(
                            `[data-repeatable-action^="add-fuse-selected-"]`
                        );
                        $add_button.click();
                    }
                    return;
                }

                const $valid_nav_items = $fuse_list.querySelectorAll(
                    '[data-hidden="false"]'
                );

                switch (event.keyCode) {
                    default:
                        // if the input has data-code-input, clear out code input value
                        if ($input.dataset.codeInput) {
                            const $code_input = document.querySelector($input.dataset.codeInput);
                            if ($code_input) {
                                $code_input.value = "";
                            }
                        }

                        // if the input has data-description-input, clear out code input value
                        if ($input.dataset.descriptionInput) {
                            const $description_input = document.querySelector($input.dataset.descriptionInput);
                            if ($description_input) {
                                $description_input.value = "";
                            }
                        }
                        break;
                    case 40: // Down Arrow
                        $input.currentFocus++;
                        lib.fuse.addActiveState($input, $valid_nav_items);
                        break;
                    case 38: // Up Arrow
                        $input.currentFocus--;
                        lib.fuse.addActiveState($input, $valid_nav_items);
                        break;
                    case 13: // Enter Key
                        event.preventDefault();
                        if ($input.currentFocus > -1 && $valid_nav_items) {
                            $valid_nav_items[$input.currentFocus].click();
                            lib.fuse.hideAllFuseList();
                        }
                        break;
                    case 27: // Escape
                        lib.fuse.hideAllFuseList();
                        break;
                }
            });
        },
        addActiveState: ($input, x) => {
            // Classify the active item
            if (!x) return false;

            // Reset active component
            lib.fuse.removeActiveState(x);
            if ($input.currentFocus >= x.length) $input.currentFocus = 0;
            if ($input.currentFocus < 0) $input.currentFocus = x.length - 1;

            // Add 'autocomplete-active' to the active item
            x[$input.currentFocus].classList.add("autocomplete-active");
            x[$input.currentFocus].scrollIntoView({
                block: "nearest",
            });
        },
        removeActiveState: (x) => {
            // Reset Active class for all autocomplete items
            for (let i = 0; i < x.length; i++) {
                x[i].classList.remove("autocomplete-active");
            }
        },
    };

    // cleans up all event handlers by replacing the element with itself
    lib.cleanHandlers = ($element) => {
        return $element.replaceWith($element.cloneNode());
    };

    /**
     * remove behaviors on elements
     */
    lib.unbehavior = {
        "fuse-dropdown": ($input) => {
            delete $input.fuse;
            delete $input.currentFocus;
            $input.removeAttribute("data-custom-input-active");
            const existing_lists = $input.parentNode.querySelectorAll(
                `[data-for="${$input.getAttribute("id")}"]`
            );
            existing_lists.forEach((list) => list.remove());
            const existing_selections = "";
            const existing_csv = "";
            return lib.cleanHandlers($input);
        },
    };

    /**
     * initialize behaviors on elements
     */
    lib.behaviors = {
        /**
         * Turns a select input into a custom dropdown
         * @param $input the input to apply behavior to
         */
        dropdown: ($input) => {
            // updated field to selected option
            if ($input.value !== "") {
                const $text_element = $input.parentNode.querySelector(
                    ".form-select .selected"
                );
                const $active_option = $input.querySelector("option[selected]");

                if ($text_element && $active_option) {
                    $text_element.innerHTML = $active_option.innerHTML;
                }
            }

            // register dropdown menu display and interaction logic
            const $select_wrapper = $input.parentNode.querySelector(".form-select");
            ["click"].forEach(event_type => {
                $select_wrapper.addEventListener(event_type, (event) => {
                    const id_for = event.target.dataset.idFor;
                    const $related_option = $select_wrapper.parentNode.querySelector(
                        "[data-id=" + id_for + "]"
                    );

                    if (event.target.dataset && event.target.dataset.listLabel) {
                        event.preventDefault();
                        return;
                    }

                    if ($select_wrapper.classList.contains("dropdown-display")) {
                        $select_wrapper.classList.remove("dropdown-display");
                    } else {
                        // hide all other dropdowns and then show ours
                        document.querySelectorAll(".form-select").forEach((element) => {
                            element.classList.remove("dropdown-display");
                        });
                        $select_wrapper.classList.add("dropdown-display");
                    }

                    if ($related_option) {
                        $select_wrapper
                            .querySelectorAll(".select-option")
                            .forEach(($option) => {
                                $option.classList.remove("active");
                            });
                        event.target.classList.add("active");
                        $select_wrapper.selected = true;
                        $input.querySelectorAll("option").forEach(($option) => {
                            if ($option.dataset.id === $related_option.dataset.id) {
                                $option.selected = "selected";
                            } else {
                                $option.selected = false;
                            }
                        });

                        $input.parentNode.querySelector(".selected").innerHTML = event.target.innerHTML;

                        // also send a dropdown selection event
                        document.dispatchEvent(
                            new CustomEvent("custom-input-selection", {
                                detail: $input
                            })
                        );
                    }

                    // fire change event on the select element
                    if ("createEvent" in document) {
                        const evt = document.createEvent("HTMLEvents");
                        evt.initEvent("change", false, true);
                        $input.dispatchEvent(evt);
                    } else {
                        $input.fireEvent("onchange");
                    }
                });
            });
        },

        /**
         * turns a select input into a fuse-enabled fuzzy search dropdown
         * @param $input the input to apply behavior to
         */
        "fuse-dropdown": ($input) => {
            // setup variables
            const collection_lookup = $input.dataset.fuseCollection;
            const collection = window.fuse_collections[collection_lookup] || {};

            // create the fuse list
            $input.fuse = new Fuse(collection, {keys: ["code", "description"]});
            $input.currentFocus = -1;

            // run logic
            const $fuse_list = lib.fuse.generateDOMResultList($input, collection);
            const $target = lib.fuse.attachFuseListToDOM($input, $fuse_list);
            lib.fuse.registerTargetEvents($input, $target);
            lib.fuse.registerInputEvents($input);
            lib.fuse.registerKeyboardEvents($input);
        },

        /**
         * transforms a fuse-dropdown to use a different list on change
         * @param $input the input to apply behavior to
         */
        "fuse-swap": ($input) => {
            $input.lastValue = $input.value;
            $input.addEventListener("change", (event) => {
                if ($input.lastValue === $input.value) {
                    return;
                }
                $input.lastValue = $input.value;
                const $parent = $input.closest($input.dataset.fuseSwapParent);
                if ($parent) {
                    let $target = $parent.querySelector($input.dataset.fuseSwapTarget);
                    if ($target) {
                        $target.value = "";
                        $target.removeAttribute("data-code");
                        $target.removeAttribute("data-description");
                        $target.dataset.fuseCollection = $input.value || "group";

                        // Placeholder Replacement
                        let placeholder,
                            label_for = $target.getAttribute('id'),
                            label = document.querySelector(`[for=${label_for}]`);
                        switch ($input.value) {
                            default:
                            case '1':
                            case '5':
                                placeholder = "Enter your Military Occupation Code or Job Title";
                                break;
                            case '2':
                            case '4':
                                placeholder = "Enter your Rating or Job Title";
                                break;
                            case '3':
                            case '6':
                                placeholder = "Enter your Air Force Specialty Code or Job Title";
                                break;
                        }
                        label.innerHTML = placeholder;

                        lib.unbehavior["fuse-dropdown"]($target);
                        const selected_codes = $parent.querySelectorAll("li[data-code]");
                        selected_codes.forEach(($element) => {
                            $element.remove();
                        });
                        const $csv_input = $parent.querySelector(".csv-list");
                        $csv_input && ($csv_input.value = "");
                        setTimeout(() => {
                            window.CustomFormElements.register();
                        }, 300);
                    }
                }
            });
        },
    };

    /**
     * registers document or body level events that are related to custom forms
     */
    lib.registerGlobalBehaviors = () => {
        // close dropdowns on body click
        ["click"].forEach(event_type => {
            document.addEventListener(event_type, function (event) {
                if (
                    !event.target.classList.contains("form-select") &&
                    !event.target.closest(".form-select")
                ) {
                    document.querySelectorAll(".form-select").forEach((element) => {
                        element.classList.remove("dropdown-display");
                    });
                }
            });
        });

        // close dropdowns on escape key
        document.addEventListener("keydown", function (event) {
            if (event.key === "Escape") {
                document.querySelectorAll(".form-select").forEach((element) => {
                    element.classList.remove("dropdown-display");
                });
            }
        });
    };

    /**
     * registers all custom element types as long as there is a supported behavior
     * for them in the format of <input data-custom-input="dropdown fuse-swap"
     */
    lib.register = () => {
        // scan and register global behaviors
        const $body = document.querySelector("body");
        if ($body.dataset.customInputGlobals !== "true") {
            lib.registerGlobalBehaviors();
            $body.dataset.customInputGlobals = "true";
        }

        // scan and register behaviors for custom inputs
        document.querySelectorAll("[data-custom-input]").forEach(($input) => {
            const is_template = $input.closest("[data-repeatable-template]");
            if (is_template) {
                return;
            }

            if (!$input.dataset.customInputActive) {
                $input.dataset.customInputActive = "true";

                const behaviors_requested = $input.dataset.customInput.split(" ");
                behaviors_requested.forEach((behavior_requested) => {
                    const behavior_available = lib.behaviors[behavior_requested];
                    if (behavior_available && typeof behavior_available === "function") {
                        lib.behaviors[behavior_requested]($input);
                    }
                });
            }
        });
    };

    lib.domReady = (callback) => {
        function handler() {
            document.removeEventListener('DOMContentLoaded', handler, false)
            window.removeEventListener('load', handler, false)
            callback() // may pass parent context here
        }

        document.addEventListener('DOMContentLoaded', handler, false)
        window.addEventListener('load', handler, false)
    }

    // attach to window and auto-register
    window.CustomFormElements = lib;
    lib.domReady(() => {
        window.CustomFormElements.register();
    });
})();
